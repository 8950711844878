:root {
  --primary-color: #57b883;
  --secondary-color: #0039551f;
  --tertiary-color: #ffffff;

  --app-background: linear-gradient(
    140deg,
    rgba(140, 207, 170, 1) 35%,
    rgba(0, 82, 122, 1) 100%
  );
  --app-background-overlay: url("assets/img/vconsyst/pattern_logo.svg");
}
