/* You can add global styles to this file, and also import other style files */
@import "variables";

$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$tertiary-color: var(--tertiary-color);

$background: var(--app-background);
$background-overlay: var(--app-background-overlay);
$background-logo: var(--app-background-logo);

$padding-hug: 25px;

$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

* {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #003956;
  background: $background;
}

span {
  &.logo {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right;
    background-image: $background-logo;
  }
}

.canvas {
  position: relative;

  background-image: $background-overlay;
  background-position: -40px 80px;
  background-repeat: no-repeat;

  height: 100%;
  width: 100%;

  @media (min-width: 640px) {
    width: 50%;
    max-width: 480px;
    margin: auto;
  }
}

a {
  color: $primary-color;
}

.scanintroduction-loader {
  .center-spinner {
    padding-top: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h3 {
    font-size: 25px;
    line-height: 40px;
    padding-top: 10px;
    text-align: center;
    color: $tertiary-color;
  }
}

@keyframes button-spinner {
  to {
    transform: rotate(360deg);
  }
}

.button-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 4px solid $secondary-color;
  border-top-color: $primary-color;
  animation: button-spinner 0.8s linear infinite;
}

.bottom-card {
  position: absolute !important;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding-bottom: 30px !important;

  background-color: $tertiary-color;
  border-radius: 27px !important;

  -webkit-box-shadow: 0px -3px 10px 4px rgba(0, 0, 0, 0.13) !important;
  -moz-box-shadow: 0px -3px 10px 4px rgba(0, 0, 0, 0.13) !important;
  box-shadow: 0px -3px 10px 4px rgba(0, 0, 0, 0.13) !important;

  &.full {
    bottom: 0;
    left: 0;
    right: 0;

    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.mat-card-header-text {
  margin: 0 !important;
}

.mat-card-header {
  display: block !important;

  .mat-card-title {
    font-size: 22px;

    &.center {
      text-align: center;
    }
  }
}

.mat-card-header,
.mat-card-actions,
.mat-card-content {
  padding: 0 20px 0 20px !important;
}

/* BUTTON THEMING */
.mat-flat-button {
  border-radius: 51px !important;

  font-weight: 400;
  padding: 5px 0px 5px 0px !important;

  &.mat-primary {
    background-color: $primary-color;

    -webkit-box-shadow: 2px 2px 11px 2px rgba(0, 0, 0, 0.14) !important;
    -moz-box-shadow: 2px 2px 11px 2px rgba(0, 0, 0, 0.14) !important;
    box-shadow: 2px 2px 11px 2px rgba(0, 0, 0, 0.14) !important;
  }

  &.mat-secondary {
    font-size: 20px;
    background-color: $secondary-color;
    color: rgba(0, 57, 85, 0.65);
  }

  &:not(.mat-secondary) {
    font-size: 25px;
  }

  &.full {
    display: block !important;
    width: 100% !important;
  }

  &.hug {
    padding-left: $padding-hug !important;
    padding-right: $padding-hug !important;
  }

  &.hug:first-child {
    margin-right: 5px;
  }
}

.circle-container {
  position: relative !important;
  text-align: center;
  padding-top: 140px;

  .ring {
    display: block;
    position: absolute;
    box-sizing: border-box;
    top: 15%;
    left: 15%;
    width: 80%;
    height: 80%;
    margin: -1em auto auto -1em;
    transform-origin: 50% 50%;
    border-radius: 50%;
    border: 1px solid $tertiary-color;
    opacity: 0;
    animation: pulsate 3s ease-out infinite;
  }
}

.circle {
  position: relative;
  margin: auto;
  display: block;
  width: 260px;
  height: 260px;
  border-radius: 50%;
  background: $tertiary-color;

  color: $primary-color;
  text-align: center;

  &.green {
    background-color: $primary-color;
    border: 8px solid $tertiary-color;
    border-color: $tertiary-color;
    color: $tertiary-color;
  }

  &.success .description {
    background-image: url("assets/img/icon_checkmark.png");
    background-repeat: no-repeat;
    background-position: center;
  }

  .description {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    h3 {
      font-size: 33px;
      display: inline;
      line-height: 40px;
      padding-top: 10px;
    }
  }
}

.pulse {
  cursor: pointer;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
  animation: pulse 2s infinite;
}

.pulse:hover {
  animation: none;
}

$opacity: 0.35;
$animation-duration: 3000ms;

.instructions {
  padding-top: 40%;
  text-align: center;

  ul {
    margin: auto !important;
    display: inline-block;

    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    opacity: $opacity;
    margin-bottom: 30px;

    color: $tertiary-color;
    text-align: left;
    font-size: 21px;
    font-weight: 500;
    transition: opacity $animation-duration;

    &.active {
      opacity: 1;
    }

    span {
      position: relative;
      margin: auto;
      margin-right: 12px;
      display: inline-block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: $primary-color;
      border: 4px solid $tertiary-color;
      border-color: $tertiary-color;
      color: $tertiary-color;
      text-align: center;

      line-height: 48px;
      font-size: 28px;
      font-weight: 500;
    }
  }
}

.mat-spinner-color circle {
  stroke: $tertiary-color !important;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/* Animation */
@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }

  45% {
    transform: scale(1.75);
    opacity: 0;
  }
}

@keyframes stop-pulsate {
  from {
    opacity: 0.4;
  }

  to {
    transform: scale(2);
    opacity: 0;
  }
}
